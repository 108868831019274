import React from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, Link} from 'react-router-dom';
import Footer from '../footer/footer';
import HeaderBanner from '../header-banner/header-banner';
import Header from '../header/header';
import NavBar from '../navbar/navbar';

import JaulaAve from './productos-de-aves/jaulas/jaulas/jaulas';
import RacionGato from './productos-de-gatos/raciones/racion/racion';
import RacionGatoHumedo from './productos-de-gatos/racionesHumedas/racion/racion';


import Racion from './productos-de-perros/raciones/racion/racion';
import RacionPerroHumedo from './productos-de-perros/racionesHumedas/racion/racion';
import RacionesPerroHumedas from './productos-de-perros/racionesHumedas/raciones';

const VistaBusqueda = () => {
  const location = useLocation ();
console.log(location)
  return (
    <>
    <HeaderBanner />
  <div className='header'>

  <div className='container'>

    <Header />
         
 
  </div>
        <NavBar />
    </div>
      <div className="container">
        <div className="row">
        <div className="col-lg-2 slide-left">

        <Link to={'/'} className="volver-btn">
              <i className="fa-solid fa-chevron-left mt-3" />Volver
            </Link>

         
          </div>

   <div className="col-lg-10 container-articulos mb-5">
   <div className="row mt-4">




      {location.state.b.map (e => {
        console.log(location.state.b)
        if (e.tipoProd === 'alimentoSeco') {
          return (
            <>
           
                <Racion key={e.id} prop={e} />

            </>
                );
              } 
        if (e.tipoProd === 'alimentoHumedoPerro') {
          return (
                <RacionPerroHumedo key={e.id} prop={e} />

                );
              } 
        if (e.tipoProd === 'alimentoSecoGato') {
          return (
                <RacionGato key={e.id} prop={e} />

                );
              } 
        if (e.tipoProd === 'alimentoHumedoGato') {
          return (
                <RacionGatoHumedo key={e.id} prop={e} />

                );
              } 
              else if (e.tipoProd === 'jaula') {
                return <JaulaAve key={e.id} prop={e} />;
              }
              
            })}
            </div>
            </div>  
            
        </div>

          </div>
      <div className="row mt-5">

        <Footer />
      </div>
    </>
  );
};

export default VistaBusqueda;
