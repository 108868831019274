import {slide as Menuu} from 'react-burger-menu';
import {Navigation} from 'react-minimal-side-navigation';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { client } from '../../supabase/cliente';
import {Sidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import './menu.css';
export default props => {
  const [search, setSearch] = useState ('');
  const [data, setData] = useState ('');
  const navigate = useNavigate ();
  const url = 'https://veterinaria-la-comercial-server.vercel.app';
  const getData = async () => {
    const res = await fetch (url);
    const data = await res.json ();
    setData (data);
    console.log (data);
  };
  useEffect (() => {
    getData ();
  }, []);
  const handleSearch = async e => {
    let productos = [];

    productos = data.filter (prod => {
     
      return prod.nombre.toLowerCase().startsWith (search.toLowerCase ())
    });
    console.log (productos);
    navigate ('/busqueda', {
      state: {
        b: productos,
      },
    });
if(search.toLowerCase().includes("frost") || search.toLowerCase().includes("bravo")|| search.toLowerCase().includes("nero")|| search.toLowerCase().includes("equilibrio")|| search.toLowerCase().includes("trotter")
   || search.toLowerCase().includes("max")|| search.toLowerCase().includes("pedigree")|| search.toLowerCase().includes("optimium")|| search.toLowerCase().includes("eukanuba")|| search.toLowerCase().includes("ecopet")
   || search.toLowerCase().includes("vet")|| search.toLowerCase().includes("monello")|| search.toLowerCase().includes("finotrato")|| search.toLowerCase().includes("pro")|| search.toLowerCase().includes("daldog")
   || search.toLowerCase().includes("monge")|| search.toLowerCase().includes("gemon")|| search.toLowerCase().includes("puppy")|| search.toLowerCase().includes("mini")|| search.toLowerCase().includes("pro")
   || search.toLowerCase().includes("sportmix")|| search.toLowerCase().includes("sabrositos")){
    const aps = await client.from("alimentos secos de perros").select("*").eq("nombre", search.toLowerCase())
  console.log (aps);
    navigate("/busqueda",{
      state: {
        b: aps.data,
      }
    })

  }  
    
    if (search.includes ('jaula')) {
      const ja = await client
        .from ('jaulas de aves')
        .select ('*')
        .eq ('marca', search.toLowerCase ());
      navigate ('/busqueda', {
        state: {
          b: ja.data,
        },
      });
    }
    if (search.includes ('peces')) {
      const ja = await client
        .from ('accesorios de peces')
        .select ('*')
        .eq ('marca', search.toLowerCase ());
      navigate ('/busqueda', {
        state: {
          b: ja.data,
        },
      });
    }
    if (search.includes ('aves')) {
      const ja = await client
        .from ('accesorios de aves')
        .select ('*')
        .eq ('marca', search.toLowerCase ());
      navigate ('/busqueda', {
        state: {
          b: ja.data,
        },
      });
    }
    if (search.includes ('roedores')) {
      const ja = await client
        .from ('alimentos de roedores')
        .select ('*')
        .eq ('marca', search.toLowerCase ());
      navigate ('/busqueda', {
        state: {
          b: ja.data,
        },
      });
    }
  };
  return (
    <>
    <Menuu {...props}>
     <div class="col-6 col-lg-5 buscar input-container buscador" >
            <div className="container-search">
              <div className="search-bar">
                <input
                  value={search} 
                  type="text"
                  placeholder="Buscar por marca"
                  onChange={e => {
                    setSearch (e.target.value);
                  }} 
                  
                />
                <button
                  type="button"
                   onClick={handleSearch} 
                  className="button-search"
                  
                >
                  <i  class="fa-solid fa-magnifying-glass" />
                </button>

              </div>

            </div>

          </div>
 

        <Sidebar>
          <Menu className="subMenu">
            <SubMenu {...props} label="Perros">
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Perros/alimentosSecos">Alimentos secos</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Perros/alimentosHumedos">Alimentos humedos</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Perros/snacks">Snacks</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Perros/higiene">Higiene</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Perros/correasycollares">Cepillos, corta uñas</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Perros/accesorios">Accesorios</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Perros/camas">Camas</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Perros/casillas">Casillas</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Perros/transportadoras">Transportadoras</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Perros/paseos">Paseos</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Perros/platos">Platos</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Perros/juguetes">Juguetes</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Perros/ropa">Ropa</Link></MenuItem>
            </SubMenu>
            <SubMenu label="Gatos">
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/alimentosSecos">Alimentos secos</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/alimentosHumedos">Alimentos humedos</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/arenassanitarias">Arenas sanitarias</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/higiene">Higiene</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/cepillos">Cepillos y corta uñas</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/accesorios">Accesorios</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/sanitarios">Sanitarios</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/camas">Camas</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/transportadoras">Transportadoras</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/paseos">Paseos</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/platos">Platos</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/juguetes">Juguetes</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/rascadores">Rascadores</Link></MenuItem>
                 <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Gatos/snacks">Snacks</Link></MenuItem>
            </SubMenu>
            <SubMenu label="Aves">
            <MenuItem className="subMenu">  <Link className="categorias-menu" to="/productos-de-Aves/alimentos">Alimentos</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Aves/jaulas">Jaulas</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Aves/accesorios">Accesorios</Link></MenuItem>
            </SubMenu>
            <SubMenu label="Peces">
            <MenuItem className="subMenu">  <Link className="categorias-menu" to="/productos-de-Peces/alimentos">Alimentos</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Peces/peceras">Peceras</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Peces/mantenimientodelagua">Mantenimiento del agua</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Peces/bombasyfiltros">Bombas y filtros</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Peces/calentadoresytermometros">Calentadores y termometros</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Peces/accesorios">Accesorios</Link></MenuItem>
              
            </SubMenu>
            <SubMenu label="Roedores">
            <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Roedores/alimentos">Alimentos</Link></MenuItem>
            <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Roedores/jaulas">Jaulas</Link></MenuItem>
           <MenuItem className="subMenu"> <Link className="categorias-menu" to="/productos-de-Roedores/accesorios">Accesorios</Link></MenuItem>
            </SubMenu>
            <SubMenu label="Reptiles">
            <MenuItem className="subMenu">  <Link className="categorias-menu" to="/productos-de-Reptiles/alimentos">Alimentos</Link></MenuItem>
       
            </SubMenu>
            <SubMenu label="Farmacia">
            <MenuItem className="subMenu">  <Link className="categorias-menu" to="/productos-de-Farmacia/antiparasitariosExternos">Antiparasitarios externos</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Farmacia/antiparasitariosInternos">Antiparasitarios internos</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Farmacia/medicamentos">Medicamentos</Link></MenuItem>
              <MenuItem className="subMenu"><Link className="categorias-menu" to="/productos-de-Farmacia/suplementos">Suplementos</Link></MenuItem>
            </SubMenu>
        
            <MenuItem className="subMenu"><Link className="categorias-menu" to="/servicios">Servicios</Link></MenuItem>
            <MenuItem className="subMenu"><Link className="categorias-menu" to="/vista-promociones">Promociones</Link></MenuItem>
          </Menu>
        </Sidebar>
  
    </Menuu>
       </>
  );
}
